import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { OpenAPIV3 } from "openapi-types";
import {
  GithubIcon,
  GoogleCalendar,
  GoogleDriveIcon,
  GoogleIcon,
  GoogleMailIcon,
  GoogleMapsIcon,
  GoogleSheets,
  HttpBinIcon,
  NetlifyIcon,
  OktaIcon,
  PetstoreIcon,
  SlackIcon,
  SpotifyIcon,
  StripeIcon,
  TwitterIcon,
  TwitterIconLarge,
  ZoomIcon,
} from "../components/icons";
import { mapValues, uniq, pick, keys } from "lodash";
import { EndpointSummaryType, makeCommaSeparatedString } from "./utils";
import { ApiNameType as _ApiNameType, API_NAMES } from "./apiNames";
import { COLORS } from "./colors";
import styled from "styled-components";

export type ApiNameType = _ApiNameType;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 34px;
  color: ${COLORS.nearWhite};
  font-weight: 800;
  line-height: 20px;
`;

type FaqType = { question: string; answer: string };
export type ApiAdditionalMetadataType = {
  [key in ApiNameType]: {
    logo: React.ReactNode;
    icon: React.ReactNode;
    signInIcon?: React.ReactNode;
    customSignInText?: string;
    // Linked from home page. Also used as fallback if no docs exist for an individual endpoint
    docsUrl: string;
    prettyName: string;
    popularOperationIds: string[];
    faqs: (
      apiMetadata: ApiMetadataType,
      apiEndpointsSummary: EndpointSummaryType[]
    ) => FaqType[];
    relatedAPIs: ApiNameType[];
    longDescription: string;
    specSource: string; // Note: we don't use this anymore (just helpful for reference)
    // Return true if you should have auth (but no specific scopes)
    scopesForOperation: (
      operation: OpenAPIV3.OperationObject
    ) => string[] | true;
    // e.g. for googlecalendar, we actually want to just use google Oauth
    oauthNameOverride?: string;
    // defaults to ",". Okta, for example, uses " "
    customScopeDelimiter?: string;
  };
};

const defaultFaqs = (
  apiName: ApiNameType,
  apiMetadata: ApiMetadataType,
  apiEndpointsSummary: EndpointSummaryType[]
): FaqType[] => {
  const prettyName = _API_ADDITIONAL_METADATA[apiName].prettyName;

  const methods = uniq(apiEndpointsSummary.map((e) => e.method))
    .map((e) => e.toUpperCase())
    .sort();

  return [
    {
      question: `How can I send requests to the ${prettyName} API?`,
      answer: `tryapis.com lets you easily send requests to ${prettyName}'s ${
        apiEndpointsSummary.length
      } ${makeCommaSeparatedString(
        methods
      )} API endpoints from your browser or from a cURL command. Find the endpoint you're looking for in the navigation bar.`,
    },
    {
      question: `How can I use the ${prettyName} API in my app or website?`,
      answer: `First, find the endpoint you need on tryapis.com. Then, add any parameters you want to pass in to the endpoint. Finally, select "Generate code" and choose your programming language of choice, like Node.js or Python.`,
    },
    {
      question: `What's the difference between tryapis.com and Postman?`,
      answer: `tryapis.com pre-populates headers, query and body parameters (with in-line docs!) to save you time. Postman is better for internal APIs that don't have OpenAPI Specs.`,
    },
    {
      question: `What version of ${prettyName}'s API does tryapis.com use?`,
      answer: `${apiMetadata.info.version} (from ${prettyName}'s latest OpenAPI spec)`,
    },
    {
      question: `Do I have to pay to use tryapis.com?`,
      answer: `No, tryapis.com is free to use.`,
    },
  ];
};

export type ApiMetadataType = Omit<OpenAPIV3.Document, "paths" | "components">;

const SHARED_PROPS = {
  alt: "Logo",
  height: 28,
};

const _API_ADDITIONAL_METADATA: ApiAdditionalMetadataType = {
  /**
   * Oauth:
   * dominicpwhyte -> Settings -> Developer settings -> Oauth apps
   *
   */
  github: {
    longDescription:
      "The GitHub REST API lets you create calls to get the data you need to integrate with GitHub.",
    prettyName: "GitHub",
    logo: (
      <StaticImage {...SHARED_PROPS} src={`../images/github.png`}></StaticImage>
    ),
    docsUrl: "https://docs.github.com/en/rest",
    icon: <GithubIcon />,
    popularOperationIds: [
      "repos-list-for-user",
      "pulls-create",
      "issues-create",
      "issues-list-comments-for-repo",
      "search-repos",
      "repos-get",
    ],
    faqs: () => [],
    relatedAPIs: ["spotify", "netlify", "slack"],
    specSource:
      "https://raw.githubusercontent.com/github/rest-api-description/main/descriptions/api.github.com/api.github.com.json",
    scopesForOperation: () => [
      "user",
      "public_repo",
      "repo",
      "notifications",
      "gist",
    ],
  },
  // petstore: {
  //   scopesForOperation: () => [],
  //   longDescription: "Petstore is a demo API created by Swagger",
  //   prettyName: "PetStore",
  //   logo: (
  //     <StaticImage
  //       src={`../images/petstore.png`}
  //       {...SHARED_PROPS}
  //     ></StaticImage>
  //   ),
  //   docsUrl: "petstore.swagger.io",
  //   icon: <PetstoreIcon />,
  //   popularOperationIds: [
  //     "updatePet",
  //     "addPet",
  //     "findPetsByStatus",
  //     "getOrderById",
  //     "getInventory",
  //     "logoutUser",
  //   ],
  //   faqs: () => [],
  //   relatedAPIs: ["httpbin", "slack", "netlify"],
  //   specSource: "",
  // },
  slack: {
    longDescription:
      "The Slack API lets you extend, expand, and automate your Slack workspaces. Cultivate conversation, inspire action, and integrate services by building apps or workflows.",
    prettyName: "Slack",
    logo: (
      <StaticImage src="../images/slack.png" {...SHARED_PROPS}></StaticImage>
    ),
    docsUrl: "https://api.slack.com/methods",
    icon: <SlackIcon />,
    popularOperationIds: [
      "chat-postMessage",
      "conversations-list",
      "users-list",
      "conversations-history",
      "admin-emoji-add",
      "chat-scheduleMessage",
    ],
    faqs: () => [],
    relatedAPIs: ["googlemail", "github", "googlecalendar"],
    specSource: "https://api.apis.guru/v2/specs/slack.com/1.7.0/openapi.json",
    scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
      return operation?.security?.[0].slackAuth || [];
    },
  },
  // stripe: {
  //   longDescription:
  //     "The Stripe API lets you interact with all of Stripe's endpoints via a REST interface.",
  //   prettyName: "Stripe",
  //   logo: (
  //     <StaticImage src="../images/stripe.png" {...SHARED_PROPS}></StaticImage>
  //   ),
  //   docsUrl: "https://stripe.com/docs/api",
  //   icon: <StripeIcon />,
  //   popularOperationIds: [
  //     "chat_postMessage",
  //     "conversations_list",
  //     "users_list",
  //     "conversations_history",
  //     "admin_emoji_add",
  //     "chat_scheduleMessage",
  //   ],
  //   faqs: () => [],
  //   relatedAPIs: ["petstore", "github", "slack"],
  //   specSource: "https://api.apis.guru/v2/specs/slack.com/1.7.0/openapi.json",
  //   scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
  //     return operation?.security?.[0].slackAuth || [];
  //   },
  // },
  httpbin: {
    longDescription:
      "The httpbin API is a simple HTTP Request & Response Service powered by httpbin.org.",
    prettyName: "httpbin",
    logo: <Title>httpbin</Title>,
    docsUrl: "https://httpbin.org/",
    icon: <HttpBinIcon />,
    popularOperationIds: [
      "get-status-codes",
      "get-html",
      "get-uuid",
      "get-delay-delay",
      "get-robots-txt",
      "put-status-codes",
    ],
    faqs: () => [],
    relatedAPIs: ["googlesheets", "slack", "github"],
    specSource:
      "https://raw.githubusercontent.com/APIs-guru/openapi-directory/main/APIs/httpbin.org/0.9.2/openapi.yaml",
    scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
      return [];
    },
  },
  spotify: {
    longDescription:
      "The Spotify Web API endpoints return JSON metadata about music artists, albums, and tracks, directly from the Spotify Data Catalogue. The Spotify Web API also provides access to user related data, like playlists and music that the user saves in the Your Music library.",
    prettyName: "Spotify",
    logo: (
      <StaticImage src="../images/spotify.png" {...SHARED_PROPS}></StaticImage>
    ),
    docsUrl: "https://developer.spotify.com/documentation/web-api/",
    icon: <SpotifyIcon />,
    popularOperationIds: [
      "endpoint-get-an-artist",
      "endpoint-get-followed",
      "endpoint-follow-playlist",
      "endpoint-unfollow-playlist",
      "endpoint-search",
      "endpoint-get-categories",
    ],
    faqs: () => [],
    relatedAPIs: ["googlemail", "googlemaps", "googlecalendar"],
    specSource:
      "https://github.com/APIs-guru/openapi-directory/blob/main/APIs/spotify.com/2021.8.15/openapi.yaml",
    scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
      // Spec is innaccurate, e.g. for endpoint_get_followed it requests the modify scope
      // return operation?.security?.[0].spotify_auth || [];
      return [
        "ugc-image-upload",
        "user-read-playback-state",
        "user-modify-playback-state",
        "user-read-currently-playing",
        "user-read-private",
        "user-read-email",
        "user-follow-modify",
        "user-follow-read",
        "user-library-modify",
        "user-library-read",
        "streaming",
        "app-remote-control",
        "user-read-playback-position",
        "user-top-read",
        "user-read-recently-played",
        "playlist-modify-private",
        "playlist-read-collaborative",
        "playlist-read-private",
        "playlist-modify-public",
      ];
    },
  },
  googlecalendar: {
    longDescription:
      "The Google Calendar API lets you integrate your app with Google Calendar, creating new ways for you to engage your users.",
    prettyName: "Google Calendar",
    logo: (
      <StaticImage
        src="../images/googlecalendar.png"
        {...SHARED_PROPS}
      ></StaticImage>
    ),
    docsUrl: "https://developers.google.com/calendar/api",
    icon: <GoogleCalendar />,
    signInIcon: <GoogleIcon />,
    customSignInText: "Sign in with Google",
    popularOperationIds: [
      "calendar-events-list",
      "calendar-events-patch",
      "calendar-calendarList-list",
      "calendar-calendars-get",
      "calendar-calendars-insert",
    ],
    faqs: () => [],
    relatedAPIs: ["googlesheets", "googledrive", "googlemail"],
    specSource:
      "https://raw.githubusercontent.com/APIs-guru/openapi-directory/main/APIs/googleapis.com/calendar/v3/openapi.yaml",
    scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
      return ["https://www.googleapis.com/auth/calendar"];
    },
    oauthNameOverride: "google",
  },
  googlesheets: {
    longDescription:
      "The Google Sheets API lets you read, write, and format Google Sheets data via a REST interface.",
    prettyName: "Google Sheets",
    signInIcon: <GoogleIcon />,
    customSignInText: "Sign in with Google",
    logo: (
      <StaticImage
        src="../images/googlesheets.png"
        {...SHARED_PROPS}
        width={157} // Maintain aspect ratio
      ></StaticImage>
    ),
    docsUrl: "https://developers.google.com/sheets/api",
    icon: <GoogleSheets />,
    popularOperationIds: [
      "sheets-spreadsheets-create",
      "sheets-spreadsheets-get",
      "sheets-spreadsheets-values-append",
    ],
    faqs: () => [],
    relatedAPIs: ["googledrive", "googlemail", "googlemaps"],
    specSource:
      "https://raw.githubusercontent.com/APIs-guru/openapi-directory/main/APIs/googleapis.com/calendar/v3/openapi.yaml",
    scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
      return ["https://www.googleapis.com/auth/spreadsheets"];
    },
    oauthNameOverride: "google",
  },
  googledrive: {
    longDescription:
      "The Google Drive API lets you interact with Google Drive storage via a REST interface.",
    prettyName: "Google Drive",
    logo: (
      <StaticImage
        src="../images/googledrive.png"
        {...SHARED_PROPS}
      ></StaticImage>
    ),
    docsUrl: "https://developers.google.com/drive/api/v3/reference",
    icon: <GoogleDriveIcon />,
    signInIcon: <GoogleIcon />,
    customSignInText: "Sign in with Google",
    popularOperationIds: [
      "drive-files-list",
      "drive-comments-list",
      "drive-comments-create",
      "drive-files-delete",
      "drive-replies-list",
      "drive-permissions-list",
    ],
    faqs: () => [],
    relatedAPIs: ["googlesheets", "googlecalendar", "googlemaps"],
    specSource:
      "https://raw.githubusercontent.com/APIs-guru/openapi-directory/main/APIs/googleapis.com/drive/v3/openapi.yaml",
    scopesForOperation: () => {
      return ["https://www.googleapis.com/auth/drive"];
    },
    oauthNameOverride: "google",
  },
  googlemail: {
    longDescription:
      "The Gmail API lets you interact with users' Gmail inboxes and settings via a REST interface.",
    prettyName: "Gmail",
    logo: (
      <StaticImage
        src="../images/googlemail.png"
        {...SHARED_PROPS}
        width={130}
      ></StaticImage>
    ),
    signInIcon: <GoogleIcon />,
    customSignInText: "Sign in with Google",
    docsUrl: "https://developers.google.com/gmail/api/reference/rest",
    icon: <GoogleMailIcon />,
    popularOperationIds: [
      "gmail-users-messages-list",
      "gmail-users-messages-get",
      "gmail-users-messages-batchDelete",
      "gmail-users-drafts-create",
      "gmail-users-messages-batchDelete",
    ],
    faqs: () => [],
    relatedAPIs: ["googlesheets", "googledrive", "googlecalendar"],
    specSource:
      "https://raw.githubusercontent.com/APIs-guru/openapi-directory/main/APIs/googleapis.com/gmail/v1/openapi.yaml",
    scopesForOperation: () => {
      return ["https://mail.google.com/"];
    },
    oauthNameOverride: "google",
  },
  googlemaps: {
    longDescription:
      "The Google Maps APIs let you interact with Google's knowledge of the real world via a REST interface. Create real-world, real-time experiences with the latest Maps, Routes, and Places features from Google Maps Platform.",
    prettyName: "Google Maps",
    logo: (
      <StaticImage
        src="../images/googlemaps.png"
        {...SHARED_PROPS}
      ></StaticImage>
    ),
    docsUrl: "https://developers.google.com/maps/documentation",
    icon: <GoogleMapsIcon />,
    signInIcon: <GoogleIcon />,
    customSignInText: "Sign in with Google",
    popularOperationIds: [
      "textSearch",
      "autocomplete",
      "elevation",
      "timezone",
      "directions",
    ],
    faqs: () => [],
    relatedAPIs: ["googlesheets", "googledrive", "googlecalendar"],
    specSource:
      "https://raw.githubusercontent.com/googlemaps/openapi-specification/main/dist/google-maps-platform-openapi3.json",
    scopesForOperation: () => {
      return [];
    },
  },
  twitter: {
    customScopeDelimiter: " ",
    longDescription:
      "The Twitter API enables programmatic access to Twitter in unique and advanced ways. Tap into core elements of Twitter like: Tweets, Direct Messages, Spaces, Lists, users, and more.",
    prettyName: "Twitter",
    logo: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <TwitterIconLarge />
        <Title style={{ marginLeft: 12 }}>Twitter</Title>
      </div>
    ),
    oauthNameOverride: "twitter2",
    docsUrl: "https://developer.twitter.com/en/docs/twitter-api",
    icon: <TwitterIcon />,
    popularOperationIds: [
      "findUserByUsername",
      "usersIdFollow",
      "createTweet",
      "usersIdTweets",
    ],
    faqs: () => [],
    relatedAPIs: ["netlify", "github", "slack"],
    // Linked from here https://developer.twitter.com/en/docs/twitter-api/tools-and-libraries/v2
    specSource: "https://api.twitter.com/2/openapi.json",
    scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
      return [
        "tweet.read",
        "tweet.write",
        "tweet.moderate.write",
        "users.read",
        "follows.read",
        "follows.write",
        "offline.access",
        "space.read",
        "mute.read",
        "mute.write",
        "like.read",
        "like.write",
        "list.read",
        "list.write",
        "block.read",
        "block.write",
      ];
    },
  },
  // zoom: {
  //   longDescription:
  //     "The Zoom API lets developers to access information from Zoom via a REST interface. You can use the API to access information about your account, create meetings, send messages, and much more.",
  //   prettyName: "Zoom",
  //   logo: (
  //     <StaticImage src="../images/zoom.png" {...SHARED_PROPS}></StaticImage>
  //   ),
  //   docsUrl: "https://marketplace.zoom.us/docs/api-reference/zoom-api",
  //   icon: <ZoomIcon />,
  //   popularOperationIds: [
  //     "users",
  //     "meetings",
  //     "meetingCreate",
  //     "createChannel",
  //     "getChannels",
  //     "sendaChatMessage",
  //   ],
  //   faqs: () => [],
  //   relatedAPIs: ["googlecalendar", "github", "slack"],
  //   // Official Zoom one is swagger 2.0 - apis.guru has converted it to 3.0, it seems
  //   specSource:
  //     "https://raw.githubusercontent.com/APIs-guru/openapi-directory/main/APIs/zoom.us/2.0.0/openapi.yaml",
  //   scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
  //     // I think this is ignored by Zoom - scopes are added based on what you put in their portal
  //     return [
  //       "account:master",
  //       "account:read:admin",
  //       "account:write:admin",
  //       "billing:master",
  //       "chat_channel:read:admin",
  //       "chat_channel:write:admin",
  //       "chat_message:read:admin",
  //       "chat_message:write:admin",
  //       "contact:read:admin",
  //       "dashboard:master",
  //       "dashboard_crc:read:admin",
  //       "dashboard_home:read:admin",
  //       "dashboard_im:read:admin",
  //       "dashboard_meetings:read:admin",
  //       "dashboard_webinars:read:admin",
  //       "dashboard_zr:read:admin",
  //       "group:master",
  //       "group:read:admin",
  //       "group:write:admin",
  //       "imchat:bot",
  //       "imchat:read:admin",
  //       "imchat:write:admin",
  //       "imcontact:read:admin",
  //       "meeting:master",
  //       "meeting:read:admin",
  //       "meeting:write:admin",
  //       "recording:master",
  //       "recording:read:admin",
  //       "recording:write:admin",
  //       "user:master",
  //       "user:read:admin",
  //       "user:write:admin",
  //       "webinar:master",
  //       "webinar:read:admin",
  //       "webinar:write:admin",
  //     ];
  //   },
  // },
  netlify: {
    longDescription:
      "The Netlify API lets you handle atomic deploys of websites, manage form submissions, inject JavaScript snippets, and much more via a REST interface.",
    prettyName: "Netlify",
    logo: (
      <StaticImage src="../images/netlify.png" {...SHARED_PROPS}></StaticImage>
    ),
    docsUrl: "https://open-api.netlify.com/",
    icon: <NetlifyIcon />,
    popularOperationIds: [
      "listSites",
      "listSiteBuilds",
      "createSiteBuild",
      "getSiteBuild",
      "updateSite",
      "listSiteForms",
    ],
    faqs: () => [],
    relatedAPIs: ["spotify", "github", "slack"],
    specSource: "https://open-api.netlify.com/",
    scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
      return true;
    },
  },
  // okta: {
  //   longDescription:
  //     "The Okta API lets you interact with Okta apps and services via a REST interface. You can use it to implement basic auth functions such as signing in your users and programmatically managing your Okta objects.",
  //   prettyName: "Okta",
  //   logo: (
  //     <StaticImage src="../images/okta.png" {...SHARED_PROPS}></StaticImage>
  //   ),
  //   docsUrl: "https://developer.okta.com/docs/reference",
  //   icon: <OktaIcon />,
  //   popularOperationIds: [
  //     "listApplications",
  //     "listUsers",
  //     "updateUser",
  //     "listGroups",
  //     "getLogs",
  //   ],
  //   faqs: () => [],
  //   relatedAPIs: ["zoom", "github", "slack"],
  //   specSource:
  //     "https://raw.githubusercontent.com/okta/okta-management-openapi-spec/master/resources/spec.yaml",
  //   customScopeDelimiter: " ",
  //   scopesForOperation: (operation: OpenAPIV3.OperationObject) => {
  //     return [
  //       "okta.apps.manage",
  //       "okta.apps.read",
  //       "okta.authorizationServers.manage",
  //       "okta.authorizationServers.read",
  //       "okta.clients.manage",
  //       "okta.clients.read",
  //       "okta.clients.register",
  //       "okta.domains.manage",
  //       "okta.domains.read",
  //       "okta.eventHooks.manage",
  //       "okta.eventHooks.read",
  //       "okta.events.read",
  //       "okta.factors.manage",
  //       "okta.factors.read",
  //       "okta.groups.manage",
  //       "okta.groups.read",
  //       "okta.idps.manage",
  //       "okta.idps.read",
  //       "okta.inlineHooks.manage",
  //       "okta.inlineHooks.read",
  //       "okta.linkedObjects.manage",
  //       "okta.linkedObjects.read",
  //       "okta.logs.read",
  //       "okta.policies.manage",
  //       "okta.policies.read",
  //       "okta.roles.manage",
  //       "okta.roles.read",
  //       "okta.schemas.manage",
  //       "okta.schemas.read",
  //       "okta.sessions.manage",
  //       "okta.sessions.read",
  //       "okta.templates.manage",
  //       "okta.templates.read",
  //       "okta.trustedOrigins.manage",
  //       "okta.trustedOrigins.read",
  //       "okta.users.manage",
  //       "okta.users.manage.self",
  //       "okta.users.read",
  //       "okta.users.read.self",
  //     ];
  //   },
  // },
};

export const API_ADDITIONAL_METADATA = mapValues(
  pick(_API_ADDITIONAL_METADATA, keys(API_NAMES)),
  (value, apiName) => ({
    ...value,
    faqs: (
      apiMetadata: ApiMetadataType,
      apiEndpointsSummary: EndpointSummaryType[]
    ) => [
      ...defaultFaqs(apiName as ApiNameType, apiMetadata, apiEndpointsSummary),
      ...(value?.faqs(apiMetadata, apiEndpointsSummary) || []),
    ],
  })
);

/**
 * MANUAL ADJUSTMENTS MADE TO SPECS:
 * Google sheets: appended "https://developers.google.com/" to the URLs of one endpoint
 * description: Appends values to a spreadsheet. The input range is used to search for existing data.....
 *
 * Okta:
 * used editor.swagger.io to convert spec to 3.0
 * removed "#/definitions/UserCredentials" on an endpoint which was causing error
 * Added a server variable for subdomain
 *
 *
 */
