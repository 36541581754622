export const API_NAMES = {
  // petstore: "petstore", // remove petstore - is useless
  github: "github",
  slack: "slack",

  httpbin: "httpbin",

  spotify: "spotify",

  netlify: "netlify",

  // WIP
  googlecalendar: "googlecalendar",
  googlesheets: "googlesheets",
  googledrive: "googledrive",
  googlemail: "googlemail",
  googlemaps: "googlemaps",

  // zoom: "zoom",
  // okta: "okta",

  // not yet available
  twitter: "twitter",
};

export type ApiNameType = keyof typeof API_NAMES;
