import React from "react";
import { createGlobalStyle } from "styled-components";
import { useKBar } from "kbar";
import { Helmet } from "react-helmet";
import { COLORS } from "../utils/colors";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import Search from "./Search";
import { ApiMetadataType, ApiNameType } from "../utils/apis";
import { EndpointSummaryType } from "../utils/utils";
import { SITE_URL } from "../utils/constants";
import socialBanner from "../images/banner.png";
import FilloutBranding from "./FilloutBranding";

const GlobalStyle = createGlobalStyle`
  body.fontLoaded {
    font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  body {
    font-family: "Inter", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: ${COLORS.backgroundDarkGray};
  }

  // For antd tooltip
  .ant-tooltip-inner {
    color: black;
    font-size: 13px;
    background-color: ${COLORS.lightGray};
    border: 1px solid ${COLORS.backgroundDarkGray}
  }
`;

const Toast = () => (
  <ToastContainer
    style={{ width: 400 }}
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

const TITLE = "tryapis.com | Easily explore popular REST APIs";
const DESCRIPTION =
  "tryapis.com lets you instantly send requests to 3rd party APIs from your browser. API parameters are pre-filled for thousands of REST endpoints from APIs like Github, Slack and Google.";

const CustomHelmet = ({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) => {
  const titleToUse = title || TITLE;
  const descriptionToUse = description || DESCRIPTION;

  return (
    <Helmet>
      <title>{titleToUse}</title>
      <meta name="description" content={descriptionToUse} />

      <meta charSet="utf-8" />
      <html lang="en"></html>

      <meta property="og:title" content={titleToUse} />
      <meta property="og:description" content={descriptionToUse} />
      <meta property="og:url" content={SITE_URL} />
      <meta property="og:site_name" content={titleToUse} />
      <meta name="twitter:image:alt" content={titleToUse} />

      <meta property="og:image" content={socialBanner} />
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

export default function Layout({
  children,
  apiMetadata,
  apiEndpointsSummary,
  apiName,
  seoTitle,
  seoDescription,
}: {
  children: React.ReactNode;
  apiMetadata: ApiMetadataType;
  apiEndpointsSummary: EndpointSummaryType[];
  apiName: ApiNameType;
  seoTitle?: string;
  seoDescription?: string;
}) {
  return (
    <div>
      <CustomHelmet title={seoTitle} description={seoDescription} />
      <Search
        apiMetadata={apiMetadata}
        apiEndpointsSummary={apiEndpointsSummary}
        apiName={apiName}
      >
        {children}
        <Toast />
        <GlobalStyle />
        <FilloutBranding />
      </Search>
    </div>
  );
}

export function LayoutNoSearch({
  children,
  seoTitle,
  seoDescription,
}: {
  children: React.ReactNode;
  seoTitle?: string;
  seoDescription?: string;
}) {
  return (
    <div>
      <CustomHelmet title={seoTitle} description={seoDescription} />
      {children}
      <Toast />
      <GlobalStyle />
      <FilloutBranding />
    </div>
  );
}
