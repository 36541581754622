import { OpenAPIV3 } from "openapi-types";
import { keys } from "lodash";

import hotkeys, { HotkeysEvent } from "hotkeys-js";
import { useCallback, useEffect } from "react";

export type MethodType = OpenAPIV3.HttpMethods;

export type EndpointType = {
  operation: OpenAPIV3.OperationObject;
  method: MethodType;
  path: string;
};

export type EndpointSummaryType = {
  method: MethodType;
  path: string;
  operationId: string;
  // What we use to group endpoints
  tag: string;
  // A short descriptor for the API
  summary: string;
};

export const getFlattenedEndpoints = (apiSpec: OpenAPIV3.Document) => {
  const paths = apiSpec.paths;
  return keys(paths).reduce((acc: EndpointType[], path) => {
    const methods = paths[path] || [];

    const acc1 = (keys(methods) as MethodType[]).map((method) => {
      const operation: OpenAPIV3.OperationObject =
        methods[method as keyof typeof methods];

      return {
        operation,
        method,
        path,
      };
    });

    return acc.concat(acc1);
  }, []);
};

export const throwError = (message: string) => {
  alert(message);
  notifySlack(["*throwError:* ", message].join(""));
};

type CallbackFn = (event: KeyboardEvent, handler: HotkeysEvent) => void;

export function useHotkeysIncludingInInputs(
  keys: string,
  callback: CallbackFn
) {
  const memoisedCallback = useCallback(callback, [callback]);

  useEffect(() => {
    hotkeys.filter = () => {
      return true;
    };
    hotkeys(keys, memoisedCallback);

    return () => hotkeys.unbind(keys);
  }, [memoisedCallback, keys]);
}

export const getBodyParams = (operation: OpenAPIV3.OperationObject) => {
  const requestBody = operation.requestBody as OpenAPIV3.RequestBodyObject;
  const contentType = Object.keys(requestBody?.content || {})[0];

  const schema = (requestBody?.content[contentType]?.schema ||
    []) as OpenAPIV3.SchemaObject;
  const properties = schema.properties as {
    [name: string]: OpenAPIV3.SchemaObject;
  };

  return {
    contentType,
    bodyParams: keys(properties).map((key) => ({
      name: key,
      required: schema.required?.includes(key),
      ...properties[key as keyof typeof properties],
    })) as OpenAPIV3.ParameterObject[],
  };
};

export const makeCommaSeparatedString = (arr: string[]) => {
  const listStart = arr.slice(0, -1).join(", ");
  const listEnd = arr.slice(-1);
  const conjunction = arr.length <= 1 ? "" : " and ";

  return [listStart, listEnd].join(conjunction);
};

// With ellipsis/ellipses
export function truncate(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export async function notifySlack(message: string) {
  try {
    await window.fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        body: JSON.stringify({ text: message }),
      }
    );
  } catch (err) {
    console.error("notifySlack error: ", err);
  }
}

export const downloadData = (
  data: stinrg,
  isJson: boolean,
  fileName: string
) => {
  const filename = `${fileName}.${isJson ? "json" : "txt"}`;
  const contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(data))], {
      type: contentType,
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    a.download = filename;
    a.href = "data:" + contentType + "," + encodeURIComponent(data);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, "g"), replace);
}

export const getLinkToOperation = (operationId: string) => {
  // tech debt - this should actually be done when parsing specs
  return `/api/${replaceAll(operationId, "_", "-")}`;
};
