import { COLORS } from "./colors";

const useLocal = process.env.NODE_ENV === "development";

export const PROXY_URL = useLocal
  ? "http://localhost:3000/v1"
  : "https://tryapisproxy.com"; // managed in route 53 (aws)

// dom todo - change when we have official URL
export const SITE_URL = useLocal
  ? "http://localhost:8000"
  : "https://tryapis.com";

export const customModalStyles = {
  content: {
    top: "35%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: COLORS.backgroundDarkGray,
    border: `none`,
    borderRadius: 8,
    boxShadow: "0px 6px 6px rgba(0, 0, 0, 0.45)",
    width: "calc(100% - 24px)",
    maxWidth: 450,
  },
  overlay: { zIndex: 1000, backgroundColor: "rgb(121 121 121 / 40%)" },
};
