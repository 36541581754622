import * as React from "react";

import { COLORS } from "../utils/colors";
import { MethodType } from "../utils/utils";

const METHOD_TO_COLOR: {
  [method in MethodType]: string;
} = {
  get: COLORS.blue,
  post: COLORS.green,
  delete: COLORS.red,
  put: COLORS.gold,
  head: COLORS.blue,
  patch: COLORS.blue,
  trace: COLORS.blue,
  options: COLORS.blue,
};

const MethodTag = ({
  method,
  style,
  size,
}: {
  method: MethodType;
  style?: React.CSSProperties;
  size: "small" | "large";
}) => {
  const borderColor = METHOD_TO_COLOR[method];
  const isLarge = size === "large";
  return (
    <p
      style={{
        borderRadius: 4,
        border: `1px solid ${borderColor}`,
        padding: isLarge ? "4px 10px" : "1px 6px",
        fontWeight: isLarge ? 500 : undefined,
        fontSize: isLarge ? 13 : 10,
        color: isLarge ? COLORS.nearWhite : COLORS.lightGray,
        opacity: isLarge ? 1 : 0.8,
        minWidth: 34,
        display: "flex",
        justifyContent: "center",
        marginBottom: 0, // added by <p>

        // lineHeight: isLarge ? "13px" : "10px",
        ...(style || {}),
      }}
    >
      {method.toUpperCase()}
    </p>
  );
};

export default MethodTag;
