export const COLORS = {
  backgroundDarkGray: "#1F2124",
  slightlyBrighterBackgroundDarkGray: "#26282b", // for the drawer
  darkGray: "#8D8D8D",
  darkDarkGray: "hsl(216deg 1% 40%)",
  lightGray: "#D7D9DB",
  blue: "#5599FF",
  green: "#0F9447",
  red: "#E74F47",
  brightDarkGray: "#2D3136",
  nearWhite: "#F7F7F8",
  gold: "#CE9133",
  primary: "#566ACD", // purple
  lightPurple: "#718FE8",
  onHoverPurple: "#7e90d9",
  textPlaceholderGray: "#8A9098",
  brightDarkGrayBorder: "#42484F",
  slightStandoutBoxGray: "#27282B",
};
