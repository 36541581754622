import React from "react";
export const LANDING_PAGE_URL = "https://fillout.com?ref=tryapis";
import styled, { CSSProperties } from "styled-components";

export const FilloutIcon = ({
  scale = 1,
  singleColor,
}: {
  scale?: number;
  singleColor?: string;
}) => (
  <>
    <svg
      width={26 * scale}
      height={21 * scale}
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="5" rx="2.5" fill={singleColor || "#EC4899"} />
      <rect
        y="8"
        width="26"
        height="5"
        rx="2.5"
        fill={singleColor || "#38BDF8"}
      />
      <rect
        y="16"
        width="16"
        height="5"
        rx="2.5"
        fill={singleColor || "#FBBF24"}
      />
      <rect
        x="17"
        y="16"
        width="9"
        height="5"
        rx="2.5"
        fill={singleColor || "#34D399"}
      />
    </svg>
  </>
);

const Parent = styled.div`
  display: none;
  display: flex;
  overflow: hidden;
  z-index: 1000;
  background-color: transparent;
  justify-content: center;
  align-items: center;

  display: flex;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  padding-bottom: 0;
`;

const StyledA = styled.a`
  display: flex;
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1.25rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  background-color: #ffffff;
  align-items: center;
  border-radius: 9999px;
  border-width: 2px;
  border-color: #f3f4f6;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  border-style: solid;

  :hover {
    box-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    border-color: #fcd34d;
  }
`;

const LightText = styled.div`
  color: #9ca3af;
  font-weight: 400;
  line-height: 0.75rem;
  font-size: 10px;
  color: 10px;
  margin-bottom: 2px;
`;

const LightIshText = styled.div`
  color: #4b5563;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  line-height: 1rem;
`;

const FilloutBranding = () => {
  return (
    <Parent>
      <StyledA
        href={LANDING_PAGE_URL}
        target="_blank"
        rel="noreferrer"
        style={{
          background: "white",
        }}
      >
        <div
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilloutIcon scale={0.98} />
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}
        >
          <LightText>A PROJECT BY</LightText>
          <LightIshText>fillout.com</LightIshText>
        </div>
      </StyledA>
    </Parent>
  );
};

export default FilloutBranding;
