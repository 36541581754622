import * as React from "react";
import { toast } from "react-toastify";
import { NO_GROUP } from "kbar/lib/useMatches";
import { navigate } from "gatsby";

import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  KBarResults,
  useMatches,
  Action,
  ActionImpl,
  useKBar,
  createAction,
  VisualState,
} from "kbar";
import styled from "styled-components";
import { COLORS } from "../utils/colors";
import {
  ApiMetadataType,
  ApiNameType,
  API_ADDITIONAL_METADATA,
} from "../utils/apis";
import { EndpointSummaryType, notifySlack, truncate } from "../utils/utils";
import { keys, values } from "lodash";
import MethodTag from "./MethodTag";
import { APIsIcon } from "./icons";

const MAX_TITLE_CHARS = 70;

const searchStyle = {
  padding: "12px 16px",
  fontSize: "16px",
  width: "100%",
  boxSizing: "border-box" as React.CSSProperties["boxSizing"],
  outline: "none",
  border: "none",
  background: COLORS.backgroundDarkGray,
  color: COLORS.nearWhite,
};

const animatorStyle = {
  maxWidth: "600px",
  border: `1px solid ${COLORS.brightDarkGray}`,
  width: "100%",
  background: COLORS.backgroundDarkGray,
  color: COLORS.darkGray,
  borderRadius: "8px",
  overflow: "hidden",
  boxShadow: "0px 6px 20px rgb(0 0 0 / 35%)",
};

const groupNameStyle = {
  padding: "8px 16px",
  fontSize: "10px",
  textTransform: "uppercase" as const,
  opacity: 0.5,
  background: COLORS.backgroundDarkGray,
};

const onRequestedItem = (requestedItem: string) => {
  notifySlack(
    [
      "*New request:* ",
      requestedItem,
      // "\n *IP Address:* ",
      // todo - add IP address here if we get a lot of spam requests
      "\n *Location:* ",
      window.location.href,
    ].join("")
  );

  toast.info(
    <>
      Thanks! We'll look into adding{" "}
      <strong style={{ color: COLORS.lightPurple }}>{requestedItem}</strong>{" "}
      support to tryapis.com
    </>,
    {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    }
  );
};

function RenderResults({ isHomePageSearch }: { isHomePageSearch: boolean }) {
  const groups = useMatches();

  const { searchQuery, currentRootActionId } = useKBar((state) => {
    return {
      searchQuery: state.searchQuery,
      currentRootActionId: state.currentRootActionId,
    };
  });

  const groupResults = groups.results;

  let filteredGroupResults = React.useMemo(
    () =>
      groupResults.filter((result) => {
        const isOnParent = !currentRootActionId;
        const isNestedResult = result?.parent && isOnParent;

        // For the home page search, we don't want "Spotify endpoints" etc. to appear when searching APIs
        const stringResultOnHomePage =
          isHomePageSearch &&
          typeof result === "string" &&
          !currentRootActionId;

        // FOr the non-home page search, we don't want titles showing up above the API names
        const superfluousTitlesWhenListingAPIs =
          !isHomePageSearch &&
          currentRootActionId &&
          typeof result === "string";

        return (
          result !== NO_GROUP &&
          !isNestedResult &&
          !stringResultOnHomePage &&
          !superfluousTitlesWhenListingAPIs
        );
      }),
    [groupResults, currentRootActionId, isHomePageSearch]
  );

  if (filteredGroupResults.length === 0) {
    filteredGroupResults = [
      {
        name: (
          <>
            No results. Suggest we add support for{" "}
            <strong style={{ color: COLORS.lightPurple }}>{searchQuery}</strong>
            ?
          </>
        ),
        command: {
          perform: () => onRequestedItem(searchQuery),
        },
      },
    ] as never[];
  }

  return (
    <KBarResults
      items={filteredGroupResults}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div style={groupNameStyle}>{item}</div>
        ) : (
          <ResultItem action={item} active={active} />
        )
      }
    />
  );
}

// eslint-disable-next-line react/display-name
const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
    }: {
      action: Action;
      active: boolean;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        style={{
          padding: "8px 16px",
          background: active
            ? COLORS.brightDarkGray
            : COLORS.backgroundDarkGray,
          borderLeft: `2px solid ${active ? COLORS.primary : "transparent"}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          {action.icon && action.icon}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ color: COLORS.darkGray }}>
              {action.name ? truncate(action.name, MAX_TITLE_CHARS) : ""}
            </span>
            {action.subtitle && (
              <span
                style={{
                  fontSize: 11,
                  color: COLORS.darkDarkGray,
                  marginTop: -2,
                }}
              >
                {action.subtitle}
              </span>
            )}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div style={{ display: "grid", gridAutoFlow: "column", gap: "4px" }}>
            {action.shortcut.map((sc) => (
              <kbd
                key={sc}
                style={{
                  padding: "4px 6px",
                  background: "rgba(0 0 0 / .1)",
                  borderRadius: "4px",
                }}
              >
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
);

const KBarPositionerWithStyles = styled(KBarPositioner)`
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 10;
`;

const endpointActionsForApi = (
  apiEndpointsSummary: EndpointSummaryType[],
  apiName: ApiNameType,
  sectionName: string,
  parent?: string
) => {
  return apiEndpointsSummary.map((endpoint) => {
    return {
      id: endpoint.operationId,
      name: endpoint.summary,
      subtitle: endpoint.path,
      shortcut: [],
      children: [],
      parent,
      keywords: `${endpoint.summary} ${endpoint.operationId} ${endpoint.path} ${endpoint.method}`,
      section: sectionName,
      perform: () => navigate(`/${apiName}/api/${endpoint.operationId}`),
      icon: (
        <div style={{ minWidth: 50, display: "flex" }}>
          <MethodTag size="small" method={endpoint.method} />
        </div>
      ),
    } as Action;
  });
};

const apiActions = (parent?: string, perform?: (key: string) => () => void) => {
  const allApis = keys(API_ADDITIONAL_METADATA) as ApiNameType[];
  return allApis.map(
    (key) =>
      ({
        id: key,
        name: API_ADDITIONAL_METADATA[key].prettyName,
        shortcut: [],
        keywords: key,
        children: [],
        section: "",
        perform: perform ? perform(key) : undefined,
        parent: parent as unknown,
        icon: API_ADDITIONAL_METADATA[key].icon,
      } as Action)
  );
};

const generateActions = (
  apiMetadata: ApiMetadataType,
  apiEndpointsSummary: EndpointSummaryType[],
  apiName: ApiNameType
): Action[] => {
  const allApis = keys(API_ADDITIONAL_METADATA) as ApiNameType[];
  const APIs = [
    {
      id: "allAPIs",
      name: "Choose another API",
      shortcut: [],
      keywords: allApis.join(" "),
      section: "All APIs",
      children: [],
      icon: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: 33,
            marginRight: 17,
          }}
        >
          <APIsIcon />
        </div>
      ),
    },
    ...apiActions("allAPIs", (key: string) => () => navigate(`/${key}`)),
  ] as Action[];

  const MAIN_SECTION = `${API_ADDITIONAL_METADATA[apiName].prettyName} Endpoints`;

  const endpoints = endpointActionsForApi(
    apiEndpointsSummary,
    apiName,
    MAIN_SECTION
  );

  return APIs.concat(endpoints);
};

const SearchSkeleton = ({
  actions,
  children,
  placeholder,
  isHomePageSearch,
}: {
  actions: Action[];
  children: React.ReactNode;
  placeholder: string;
  isHomePageSearch: boolean;
}) => {
  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositionerWithStyles>
          <KBarAnimator style={animatorStyle}>
            <KBarSearch style={searchStyle} placeholder={placeholder} />
            <RenderResults isHomePageSearch={isHomePageSearch} />
          </KBarAnimator>
        </KBarPositionerWithStyles>
      </KBarPortal>
      {children}
    </KBarProvider>
  );
};

const Search = ({
  children,
  apiMetadata,
  apiEndpointsSummary,
  apiName,
}: {
  children: React.ReactNode;
  apiMetadata: ApiMetadataType;
  apiEndpointsSummary: EndpointSummaryType[];
  apiName: ApiNameType;
}) => {
  const actions = generateActions(apiMetadata, apiEndpointsSummary, apiName);

  return (
    <SearchSkeleton
      actions={actions}
      placeholder={`Search ${apiName} endpoints…`}
      isHomePageSearch={false}
    >
      {children}
    </SearchSkeleton>
  );
};

const generateHomeActions = (apiData: {
  [apiName: string]: {
    apiName: ApiNameType;
    apiMetadata: ApiMetadataType;
    apiEndpointsSummary: EndpointSummaryType[];
  };
}): Action[] => {
  const allApis = keys(API_ADDITIONAL_METADATA) as ApiNameType[];
  // todo - find out how to set placeholder without changing name
  // e.g. `Choose a ${
  //   API_ADDITIONAL_METADATA[a.id as ApiNameType].prettyName
  // } endpoint`;
  let APIs = [...(apiActions() as Action[])] as Action[];

  for (const api of values(apiData)) {
    APIs = APIs.concat({
      id: `${api.apiName}-all`,
      name: `Browse all ${
        API_ADDITIONAL_METADATA[api.apiName].prettyName
      } endpoints`,
      shortcut: [],
      keywords: allApis.join(" "),
      // section: 'All',
      perform: () => navigate(`/${api.apiName}`),
      icon: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: 33,
            marginRight: 17,
          }}
        >
          {API_ADDITIONAL_METADATA[api.apiName].icon}
        </div>
      ),
      parent: api.apiName,
    } as Action).concat(
      endpointActionsForApi(
        api.apiEndpointsSummary,
        api.apiName,
        `${API_ADDITIONAL_METADATA[api.apiName].prettyName} endpoints`,
        api.apiName
      )
    );
  }

  return APIs;
};

export const HomePageSearch = ({
  children,
  apiData,
}: {
  children: React.ReactNode;
  apiData: {
    [apiName: string]: {
      apiName: ApiNameType;
      apiMetadata: ApiMetadataType;
      apiEndpointsSummary: EndpointSummaryType[];
    };
  };
}) => {
  const actions = generateHomeActions(apiData);

  return (
    <SearchSkeleton
      actions={actions}
      placeholder="Choose an API"
      isHomePageSearch
    >
      {children}
    </SearchSkeleton>
  );
};

export default Search;
